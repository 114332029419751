export class ContainerType {
    public nr: number;
    public singular: string;
    public plural: string;

    constructor(nr: number, sing: string, plur: string) {
        this.nr = nr;
        this.singular = sing;
        this.plural = plur;
    }
}
