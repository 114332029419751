import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';  // replaces previous Http service
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OkvistaService } from './okvista.service';   // our custom service, see below

import { AppComponent } from './app.component';
import { ContainerComponent } from './container/container.component';
import { ContainerListComponent, FocusInput } from './container-list/container-list.component';
import { ContainerSearchComponent } from './container-search/container-search.component';


@NgModule({
    declarations: [
        AppComponent,
        ContainerComponent,
        ContainerListComponent,
        ContainerSearchComponent,
        FocusInput
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule
    ],
    providers: [OkvistaService],
    bootstrap: [AppComponent]
})
export class AppModule { }
