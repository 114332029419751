import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as jQuery from 'jquery';
(<any>window).jQuery = jQuery;
(<any>window).$ = jQuery;

import * as Popper from 'popper.js';
(<any>window).Popper = Popper.default;

//import 'bootstrap-material-design';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
