import { Component, OnInit, ViewEncapsulation, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { OkvistaService } from "./okvista.service";
import { ContainerType } from "./container-type";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {
    title = 'app';
    contButton = null;
    contForm: FormGroup;
    typeButton = null;
    typeForm: FormGroup;
    inputTypeSingular: AbstractControl;
    inputTypePlural: AbstractControl;
    types: ContainerType[] = [];


    typeValidator(): ValidatorFn {
        var existingTypes = [];
        this.okvistaService.types$.subscribe(types => {
            for(let t of types) {
                existingTypes.push(t.singular);
                existingTypes.push(t.plural);
            }
        });
        return (control: FormControl): { [s: string]: boolean } => {
            let s = control.value == null ? "" : control.value;
            if (existingTypes.includes(s.toLowerCase())){
                return {existingType: true};
            }
        }
    }

    contTypeValidator(): ValidatorFn {
        var existingTypes = [];
        this.okvistaService.types$.subscribe(types => {
            existingTypes = types;
        });
        return (control: FormControl): { [s: string]: boolean  } => {
            let s = control.value == null ? "" : control.value;
            if (existingTypes.includes(s.toLowerCase()))
                return {invalidType: true};
        }
    }

    contNumberValidator(): ValidatorFn {
        return (control: FormControl): { [s: string]: boolean } => {
            if (control.value == null)
                return {invalidNumber: true}
        }
    }

    constructor(private renderer: Renderer2, fb: FormBuilder, private okvistaService: OkvistaService) {
        this.typeForm = fb.group({
            'inputTypeSingular': ['', Validators.compose([Validators.required, this.typeValidator()])],
            'inputTypePlural': ['', Validators.compose([Validators.required, this.typeValidator()])]
        });
        this.contForm = fb.group({
            'inputType': ['', Validators.compose([Validators.required, this.contTypeValidator()])],
            'inputNumber': ['', Validators.compose([Validators.required, this.contNumberValidator()])]
        });
    }

    addButtonClicked(btn) {}

    showContainerModal() {
        // Spawn some new dialog to add new container
        // Might be a modal with a dropdown for type
        ($('[data-toggle="popover"]') as any).popover('hide');
        ($('#containerModal') as any).modal({
            backdrop: 'static'
        });
    }

    showTypeModal() {
        // Spawn modal with two fields;
        // one for singular and one for plural of the type.
        ($('[data-toggle="popover"]') as any).popover('hide');
        ($('#typeModal') as any).modal({
            backdrop: 'static'
        });
    }

    onContainerAddSubmit(form: any) {
        ($('#containerModal') as any).modal('hide');
        this.contForm.reset();
        let t = form.inputType;
        let n = form.inputNumber;
        this.okvistaService.createContainer(t, n);
        ($('#focusedContainer') as any).modal('show');
    }

    onTypeSubmit(form: any){
        console.log(form);
        ($('#typeModal') as any).modal('hide');
        this.typeForm.reset();
        let sing = form.inputTypeSingular;
        let plur = form.inputTypePlural;
        this.okvistaService.createContainerType(sing, plur);
    }

    ngOnInit() {
        ($("#addButton") as any).popover({
            container: 'body',
            content: '<ul><li id="addContainerButton">Container</li><li id="addTypeButton">Type</li></ul>',
            html: true
        });
        $("#addButton").on('inserted.bs.popover', () => {
            this.contButton = this.renderer.listen($('#addContainerButton')[0], 'click', (event) => {
                this.showContainerModal();
            }) 
            this.typeButton = this.renderer.listen($('#addTypeButton')[0], 'click', (event) => {
                this.showTypeModal();
            }) 
        });
        this.okvistaService.updateContainerTypes();
        this.okvistaService.types$.subscribe(types => {
            this.types = types;
        })
    }
}
