import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Container } from './container';
import { Item } from './item';
import { ContainerType } from './container-type';

import { catchError, map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/catch';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

interface PostResponse {
    container_nr: number;
    container_type: number;
}

interface GetResponse {
    type_id: number;
    type_singular: string;
    type_plural: string;
}

@Injectable()
export class OkvistaService {

    constructor(private http:HttpClient) { }

    private ApiPath = "https://okvista.knohult.se/api/";
    //private ApiPath = "http://localhost:4000/api/";

    private _containers: BehaviorSubject<Container[]> = new BehaviorSubject(null);
    public containers$ = this._containers.asObservable();
    
    private _types: BehaviorSubject<ContainerType[]> = new BehaviorSubject([]);
    public types$ = this._types.asObservable();

    private _focusedContainer: BehaviorSubject<Container> = new BehaviorSubject<Container>(new Container(0,new ContainerType(0,"","")));
    public focusedContainer$ = this._focusedContainer.asObservable();

    getContainersFromSearch(query:string) {
        // Perhaps do some smart query processing to enable 'smart' search.
        // If you'd include the type in the search query as the first term then
        // you'll filter the results to that specific type.
        var httpError : HttpErrorResponse;
        var ret = this.http.get(this.ApiPath + "items/" + query).catch((err: HttpErrorResponse) => {
            if (err.status == 500) {
                // Something went wrong.
                console.log(err);
                httpError = err;
            }
            else if (err.status == 404) {
                console.log(err);
                httpError = err;
            }
            this._containers.next([]);
            return [];
        });

        if (httpError != undefined){
            console.log(httpError);
            return;
        }

        ret.pipe(
            map(json => {
                var containers = [];
                var types = [];
                this.types$.subscribe(typesArray => {
                    types = typesArray;
                });

                // We need to process the rows of items and put them into their own containers.
                // We can do this by nesting two maps. The first layer will represent the
                // container type and the second one will be the container number.
                var cont_type_map = new Map();
                var cont_nr_map = new Map();

                for (var index in json){
                    // For each row
                    let item = json[index];
                    cont_nr_map = cont_type_map.get(item.location_type);

                    // Check if the underlying map exists
                    if (cont_nr_map == undefined) {
                        cont_nr_map = new Map();
                    }

                    // Check if there is a list to put items into
                    var container = cont_nr_map.get(item.location_nr);
                    if (container == undefined){
                        var t = null;
                        for(var a in types)
                            if (types[a].nr == item.location_type)
                                t = types[a];
                        container = new Container(item.location_nr, t);
                    }
                    container.addItem(new Item(item.item_id, item.name));

                    cont_nr_map.set(item.location_nr, container);
                    cont_type_map.set(item.location_type, cont_nr_map);
                }

                // Populate the return array
                cont_type_map.forEach((val) => {
                    val.forEach((val1) => {
                        containers.push(val1);
                    });
                });

                return containers;
            })).subscribe(containers => {
                this._containers.next(containers);
            });
    }

    getContainerFromId(nr: number, type: number) {

        var httpError : HttpErrorResponse;
        var val : Container = null;
        return this.http.get(this.ApiPath + "containers/types/"+type+"/"+nr).catch((err: HttpErrorResponse) => {
                console.log(err);
                this._containers.next([]);
                return null;
        }).pipe(map(rows => {
            var cont_type = null;
            this.types$.subscribe(t => {
                for (var tt in t){
                    if (t[tt].nr == type)
                        cont_type = t[tt];
                }
            });
            var cont = new Container(nr, cont_type);
            for (var row in rows) {
                var item = rows[row];
                cont.addItem(new Item(item.item_id, item.name));
            }
            return cont;
        }));
    }

    setFocusedContainer(target: Container) {
        console.log(target);
        this._focusedContainer.next(target);
    }

    createContainerType(singular: string, plural: string) {
        console.log("Singular", singular);
        console.log("Plural", plural);

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        let body = new HttpParams();
        body = body.set('singular', singular);
        body = body.set('plural', plural);
        this.http.post(this.ApiPath + "containers/types", body, {headers: headers}).catch((err: HttpErrorResponse) => {
            console.error("Error",err);
            return Observable.throw(new Error(err.message));
        }).subscribe(res => {
            //console.log(res.status);
            this.updateContainerTypes();
        });
    }

    createContainer(type: number, num: number) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        let body = new HttpParams();
        body = body.set('number', num.toString());
        body = body.set('type', type.toString());
        this.http.post<PostResponse>(this.ApiPath + "containers", body, {headers: headers, responseType: "json"}).catch((err: HttpErrorResponse) => {
            console.error("Error",err);
            return Observable.throw(new Error(err.message));
        }).subscribe(res => {
            console.log(res);
            //this.setFocusedContainer(this.getContainerFromId(res.container_nr, res.container_type));
            this.getContainerFromId(res.container_nr, res.container_type).subscribe(cont => this.setFocusedContainer(cont));
        });
    }

    saveContainer(container: Container, itemsToRemove: number[]) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        var body = new HttpParams();
        body = body.set('itemsToRemove', itemsToRemove.toString());
        this.http.delete(this.ApiPath + "items", {headers: headers, params: body}).subscribe();

        body = new HttpParams();
        body = body.set('container', JSON.stringify(container));
        return this.http.put(this.ApiPath + "items", body, {headers: headers});
    }

    updateContainerTypes() {
        var ret = this.http.get<GetResponse[]>(this.ApiPath + "containers/types").catch((err: HttpErrorResponse) => {
            console.error(err);
            return Observable.throw(new Error(err.message));
        });

        ret.subscribe(types => {
            var typesArray: ContainerType[] = [];
            types.forEach(t => {
                typesArray.push(new ContainerType(t.type_id,t.type_singular,t.type_plural));
            });

            this._types.next(typesArray);
        });
    }
}
