import { Component,Input, OnInit, HostBinding, HostListener, ElementRef } from '@angular/core';
import { Item } from '../item';
import { Container } from '../container';
import { ContainerType } from '../container-type';
import { OkvistaService } from '../okvista.service';


@Component({
    selector: 'app-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

    @Input() container;

    nr: number;
    type: ContainerType;
    content: Item[];
    private element: any;
    get c_name(): string {
        return this.type.singular;
    }

    @HostBinding('class')
    cssClass : string = this.getClassBasedOnScreen();

    @HostListener('window:resize', ['$event'])
    setClassBasedOnScreen():void{
        this.cssClass = this.getClassBasedOnScreen();
    }
    getClassBasedOnScreen():string{
        if (window.screen.width < 800)
            return "container";
        else
            return "col-md";
    }

    onClick(btn) {
        console.log(this);
        this.okvistaService.setFocusedContainer(this.container);
        ($('#focusedContainer') as any).modal({
            backdrop: 'static'
        });
        if ($(this.element)[0].classList.contains('active')){
            $(this.element.localName).each((index, sibling) => {
                sibling.classList.remove('inactive');
                sibling.classList.remove('active');
            });
        }
        else {
            $(this.element.localName).each((index, sibling) => {
                sibling.classList.add('inactive');
            });
            $(this.element)[0].classList.remove('inactive'); 
            $(this.element)[0].classList.add('active'); 
        }
    }

    constructor(private e: ElementRef, private okvistaService: OkvistaService) {
        this.element = e.nativeElement;
    }

    ngOnInit() {
        this.nr = this.container.nr;
        this.type = this.container.type;
        this.content = this.container.content;
    }

}
