import { Item } from './item';
import { ContainerType } from './container-type'

export class Container {
    nr: number;
    type: ContainerType;
    content: Item[];

    public getName(): string {
        return this.type.singular;
    }

    constructor(nr: number, type: ContainerType){
        this.nr = nr;
        this.type = type;
        this.content = [];
    }

    addItem(item: Item) {
        this.content.push(item);
    }

    removeItemById(id: number) {
        let findResult = this.content.find(x => x.id === id);
        if (findResult == undefined) return;
        this.content.splice(this.content.indexOf(findResult),1);
    }
}
