import { Component, Input, OnInit, Renderer2, Renderer, Directive, ElementRef } from '@angular/core';
import {OkvistaService} from '../okvista.service'
import {Observable} from 'rxjs/Observable';
import {Container} from '../container';
import {Item} from '../item';

@Component({
    selector: 'app-container-list',
    templateUrl: './container-list.component.html',
    styleUrls: ['./container-list.component.scss']
})
export class ContainerListComponent implements OnInit {

    public containers: Container[];
    public focusedContainer: Container;
    private removeList: number[] = [];

    constructor(private okvistaService: OkvistaService){}

    ngOnInit():void {
        this.okvistaService.containers$.subscribe(containers => {
            this.containers = containers;
        });

        this.okvistaService.focusedContainer$.subscribe(container => {
            this.focusedContainer = container;
        })

        $('#focusedContainer').on('shown.bs.modal', function() {
            $(this).on('change keydown keyup paste cut', 'textarea', function() {
                $(this).height(0).height(this.scrollHeight - 12); // Fix for input field
            }).find('textarea').change();
        });
    }

    public saveModal() {
        console.log(this);
        console.log("RemoveList", this.removeList);
        this.okvistaService.saveContainer(this.focusedContainer, this.removeList).subscribe(res => {
            this.removeList = [];
            this.okvistaService.getContainersFromSearch(<string>$('#search-component input').val());
        });
    }

    public closeModal() {
        console.log(this);
        this.removeList = [];
        this.okvistaService.getContainersFromSearch(<string>$('#search-component input').val());
    }

    public addItem() {
        // Add an item input field to the container
        this.focusedContainer.addItem(new Item());
        console.log($(this.focusedContainer.content[this.focusedContainer.content.length-1]));
    }
    // TODO: Delete by reference instead of number as you might want to
    // delete an item that you've just added which will have nr: null
    public removeItem(nr: number){
        // Remove the item completely for now.
        // Later we can put the item in limbo instead.
        console.log("Nr", nr);
        this.removeList.push(nr);
        this.focusedContainer.removeItemById(nr);
    }

    public debugPrint() {
        console.log(this.containers);
    }

}

@Directive({
    selector: 'textarea'
})
export class FocusInput implements OnInit {
  constructor(public renderer: Renderer, public elementRef: ElementRef) {}

  ngOnInit() {
    this.elementRef.nativeElement.focus();
  }
}