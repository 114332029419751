import { Component, OnInit } from '@angular/core';
import { OkvistaService } from '../okvista.service';

import { Observable } from 'rxjs/Observable';
import { Subject }    from 'rxjs/Subject';
import { of }         from 'rxjs/observable/of';

import {
    debounceTime, distinctUntilChanged, switchMap
} from 'rxjs/operators';

@Component({
    selector: 'app-container-search',
    templateUrl: './container-search.component.html',
    styleUrls: ['./container-search.component.scss']
})
export class ContainerSearchComponent implements OnInit {

    private searchTerms = new Subject<string>();
    constructor(private okvistaService: OkvistaService) { }

    ngOnInit() {
        this.searchTerms.pipe(
            // wait 300ms after each keystroke before considering the term
            debounceTime(200),

            // ignore new term if same as previous term
            distinctUntilChanged(),

            // switch to new search observable each time the term changes
        ).subscribe((term: string) => this.okvistaService.getContainersFromSearch(term));
    }


    search(term:string){
        //this.okvistaService.getContainersFromSearch(term);
        this.searchTerms.next(term);
    }

}
